import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import ContactForm from 'components/forms/contact';
import Animated from 'components/AnimateComponent';

export default function Section_11() {
    return (
        <main className="home__section__11">
            <div className="mg__overlay mg__overlay--dark-3">
                <section className="mg__section__container mg__section__container--relative">
                    <section className="block__title">
                        <Animated animation={'animate__fadeInUp'}>
                            <h1>¡NO TE QUEDES FUERA DE LA REVOLUCIÓN DEL AGAVE CON MAGUEYTOKEN!</h1>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <ContactForm />
                        </Animated>

                        <section className="mg__section__container mg__section__container--relative">
                            <div className="block__title__info">
                                <Animated animation={'animate__fadeInUp'}>
                                    <h1 className="text-white">
                                        ¡SE DUEÑO DE TUS PLANTAS <br />
                                        DESDE <span className="text-yellow">$4,000.00</span>!
                                    </h1>
                                </Animated>

                                <Animated animation={'animate__fadeInUp'}>
                                    <ActionButton
                                        text="¡COMIENZA A CRECER YA!"
                                        type={'big'}
                                        icon={<MdOutlineKeyboardArrowRight />}
                                        onClick={() => {
                                            window.open('https://magueytoken.io/login', '_blank');
                                        }}
                                    />
                                </Animated>
                            </div>
                        </section>
                    </section>
                </section>
            </div>
        </main>
    );
}
