import { configureStore } from "@reduxjs/toolkit";
import componentSlice from "./slices/components.slice";
import { mailingApi } from "reduxStore/services/mailing.services";


export const store = configureStore({
    reducer: {
        componentSlice,
        [mailingApi.reducerPath]: mailingApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([
            mailingApi.middleware,
        ]),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
