import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Animated from 'components/AnimateComponent';

export default function Section_6() {
    return (
        <main className="home__section__6">
            <section className="mg__section__container mg__section__container--relative">
                <section className="home__section__6__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>SEMILLAS DE PROSPERIDAD: OBJETIVOS DE TU PATRIMONIO</h1>
                    </Animated>
                    <Animated animation={'animate__fadeInUp'}>
                        <h2>
                            Con tu participación contribuyes al desarrollo sostenible y al bienestar
                            de las comunidades locales en Oaxaca
                        </h2>
                    </Animated>
                </section>
            </section>

            <div className="home__section__6__footer">
                <Animated animation={'animate__fadeInUp'}>
                    <ActionButton
                        text="¡Agenda una llamada ahora mismo!"
                        type={'big'}
                        icon={<MdOutlineKeyboardArrowRight />}
                        onClick={() => {
                            window.open('https://magueytoken.io/login', '_blank');
                        }}
                    />
                </Animated>

                <Animated animation={'animate__fadeInUp'}>
                    <h3>
                        Nuestro modelo de negocio sólido y transparente garantiza la seguridad de tu
                        riqueza.
                    </h3>
                </Animated>
            </div>
        </main>
    );
}
