
import beneficio_1 from "assets/images/home/beneficio-1.png";
import beneficio_2 from "assets/images/home/beneficio-2.png";
import beneficio_3 from "assets/images/home/beneficio-3.png";
import beneficio_4 from "assets/images/home/beneficio-4.png";
import beneficio_5 from "assets/images/home/beneficio-5.png";
import beneficio_6 from "assets/images/home/beneficio-6.png";
import { title } from "process";


export const BeneficiosData = [
    {
        title:"ACCESIBILIDAD" ,
        title2:"FINANCIERA" ,
        icon:beneficio_1
    },

    {
        title:"POTENCIAL DE" ,
        title2:"CRECIMIENTO" ,
        icon:beneficio_2
    },

    {
        title:"CADENA DE" ,
        title2:"SUMINISTRO" ,
        icon:beneficio_3
    },

    {
        title:"CRECIMIENTO",
        title2:"SOSTENIBLE",
        icon:beneficio_4
    },

    {
        title:"TRANSPARENCIA" ,
        title2:"Y SEGURIDAD" ,
        icon:beneficio_5
    },

    {
        title:"COMPROMISO SOCIAL",
        title2:"Y AMBIENTAL",
        icon:beneficio_6
    }

]