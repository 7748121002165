import React from 'react';
import experiencia from 'assets/images/home/experiencia.png';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Animated from 'components/AnimateComponent';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal, setVideo, setVideoSize } from 'reduxStore/slices/components.slice';
export default function Section_10() {
    const dispatch = useAppDispatch();

    return (
        <main className="home__section__10">
            <div className="nv__shading__layer"></div>
            <section className="mg__section__container mg__section__container--relative">
                <section className="home__section__10__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>¡No todo a terminado!</h1>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <h2>Te presentamos:</h2>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <div className="home__section__10__logo">
                            <img src={experiencia} alt="Maguey Token Logo" />
                        </div>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            "MT Experience", un evento exclusivo para los miembros de la familia
                            MAGUEYTOKEN. Únete a nosotros para una aventura única en nuestras
                            plantaciones en Oaxaca, donde podrás explorar el proceso del agave,
                            degustar mezcales artesanales y disfrutar de la auténtica comida
                            oaxaqueña.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            Conecta con otros miembros de nuestra comunidad que comparten tu pasión
                            y visión. Experimenta la cultura vibrante de Oaxaca mientras conoces
                            nuestras tradiciones ancestrales.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            ¡Queremos verte en la próxima "MT Experience" para compartir momentos
                            inolvidables y celebrar nuestra pasión por el agave y la cultura
                            oaxaqueña!
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <ActionButton
                            text="Ver el video"
                            type={'big clear'}
                            icon={<MdOutlineKeyboardArrowRight />}
                            onClick={() => {
                                dispatch(setDisplayModal(true));
                                dispatch(
                                    setVideo('https://mtexperience.magueytoken.com/VIMME.mp4'),
                                );
                                dispatch(setVideoSize('mg__video__full'));
                            }}
                        />
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <ActionButton
                            text="¡Quiero asistir!"
                            type={'big'}
                            icon={<MdOutlineKeyboardArrowRight />}
                            onClick={() => {
                                window.open('https://magueytoken.io/login', '_blank');
                            }}
                        />
                    </Animated>
                </section>

                <section className="home__section__10__container"></section>
            </section>
        </main>
    );
}
