import React from 'react';
import { comunidadData } from 'data/comunidad';
import ComunityComponente from 'components/comunidad';
import Animated from 'components/AnimateComponent';

export default function Section_4() {
    return (
        <main className="home__section__4">
            <section className="block__title">
                <Animated animation={'animate__fadeInUp'}>
                    <h1>Conoce a nuestra comunidad</h1>
                </Animated>
            </section>

            <section className="mg__section__container mg__section__container--relative">
                {comunidadData.map((item, index) => {
                    return <ComunityComponente item={item} />;
                })}
            </section>
        </main>
    );
}
