import React from 'react';
import Animated from 'components/AnimateComponent';

interface SquareInfoProps {
    title: string;
    title2: string;
    icon: string;
}
export default function SquareInfo({ title, title2, icon }: SquareInfoProps) {
    return (
        <section className="mg__sqareInfo__item">
            <Animated animation={'animate__fadeInUp'}>
                <div className="mg__sqareInfo__item__content">
                    <div className="mg__sqareInfo__item__icon">
                        <img src={icon} />
                    </div>
                    <div className="mg__sqareInfo__item__title">
                        <h3>
                            {title}
                            <br />
                            {title2}
                        </h3>
                    </div>
                </div>
            </Animated>
        </section>
    );
}
