import React from 'react';

import { MGInput } from 'components/inputs/mg-input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactFormSchema } from 'schemas/contact.form.schema';
import { ContactFormInterface } from 'interfaces/contact.form.interface';
import { useSendFormDataMutation } from 'reduxStore/services/mailing.services';
import config from 'config';
import { message } from 'antd';

export default function ContactForm() {
    const [sendEmailData] = useSendFormDataMutation();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<ContactFormInterface>({
        resolver: yupResolver(contactFormSchema),
        shouldFocusError: false,
    });

    const onSubmit: SubmitHandler<ContactFormInterface> = async (data) => {
        const formData = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            folder: config.EMAIL_FOLDER,
            templateHTML: config.EMAIL_TEMPLATE,
            fromEmail: config.EMAIL_FROM_SEND,
            ToEmail: config.EMAIL_TO_SEND,
            subject: 'Nuevo correo desde el sitio de Maguetoken',
            attachments: [
                {
                    filename: 'logo.png',
                    cid: 'logo@cid',
                },
            ],
        };
        const __sendFormData = await sendEmailData(formData).unwrap();
        reset();
        message.success('Mensaje enviado correctamente', 3);
    };

    return (
        <div className="contact__form">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <MGInput
                    name="name"
                    type="text"
                    placeholder="Nombre"
                    control={control}
                    className=""
                    errorMessage={errors.name?.message}
                />

                <MGInput
                    name="phone"
                    type="text"
                    placeholder="Número"
                    control={control}
                    className=""
                    errorMessage={errors.phone?.message}
                />

                <MGInput
                    name="email"
                    type="text"
                    placeholder="Email"
                    control={control}
                    className=""
                    errorMessage={errors.email?.message}
                />

                <input type="submit" value="Enviar" />
            </form>
        </div>
    );
}
