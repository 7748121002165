
import comunidadImg1 from "assets/images/comunidad/comunidad-1.png";
import comunidadImg2 from "assets/images/comunidad/comunidad-2.png";
import comunidadImg3 from "assets/images/comunidad/comunidad-3.png";
import comunidadImg4 from "assets/images/comunidad/comunidad-4.png";
import video1 from "assets/videos/comunidad/video_1.mp4";
import video2 from "assets/videos/comunidad/video_2.mp4";
import video3 from "assets/videos/comunidad/video_3.mp4";
import video4 from "assets/videos/comunidad/video_4.mp4";

export const comunidadData = [
    {
        name:"SAYRA",
        subtitle:"Empresaria de Ciudad de México",
        description:"Apostar en la industria del agave fue la mejor decisión que he tomado. Gracias a MAGUEYTOKEN, he visto un retorno increíble en un corto período de tiempo. No solo estoy ganando financieramente, sino que también me siento bien al saber que mi inversión está apoyando a las comunidades locales de Oaxaca.",
        image:comunidadImg1,
        video:{video1},
        position:"left"
    },

    {
        name:"DAVID",
        subtitle:"Financiero de Guadalajara",
        description:"Siempre he buscado oportunidades con potencial de crecimiento y estabilidad. Apostar en la industria del agave ha sido una revelación. MAGUEYTOKEN no solo es modelo rentable, sino que también he sido testigo del impacto positivo que tienen en el desarrollo económico y social del estado.",
        image:comunidadImg2,
        video:video2,
        position:"right"

    },

    {
        name:"MARÍA",
        subtitle:"Chef y Restaurantera de Cancún",
        description:"Como chef apasionada por los sabores auténticos de México, ser parte de la industria del agave era una elección natural para mí. Gracias a MAGUEYTOKEN, he podido no solo expandir mi cartera, sino también acceder a mezcales de alta calidad para mi restaurante. Estoy encantada con los resultados y la oportunidad de contribuir al crecimiento de la industria.",
        image:comunidadImg3,
        video:video3,
        position:"left"
    },
    {
        name:"ROBERTO",
        subtitle:"Entusiasta de la Gastronomía de Monterrey",
        description:"Inyectar mi economía en el agave no solo ha sido una decisión financiera inteligente, sino también una experiencia gratificante. Con MAGUEYTOKEN, he sido testigo del resurgimiento de la industria del mezcal y he participado en su éxito.",
        image:comunidadImg4,
        video:video4,
        position:"right"
    }

]