import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import SquareInfo from 'components/square-info';
import { BeneficiosData } from 'data/beneficios';
import Animated from 'components/AnimateComponent';

export default function Section_3() {
    return (
        <main className="home__section__3">
            <div className="mg__overlay mg__overlay--dark-5">
                <section className="mg__section__container mg__section__container--relative">
                    <section className="header">
                        <div className="header__titles">
                            <Animated animation={'animate__fadeInUp'}>
                                <h1>
                                    <span className="text-yellow">BENEFICIOS</span> DE TENER A{' '}
                                </h1>
                            </Animated>

                            <Animated animation={'animate__fadeInUp'}>
                                <h1>
                                    MAGUEYTOKEN® <span className="text-yellow">EN TU CARTERA</span>{' '}
                                </h1>
                            </Animated>
                        </div>
                        <div className="header__contact text-center">
                            <Animated animation={'animate__fadeInUp'}>
                                <ActionButton
                                    text="Lo quiero ahora"
                                    type={'big'}
                                    icon={<MdOutlineKeyboardArrowRight />}
                                    onClick={() => {
                                        window.open('https://magueytoken.io/login', '_blank');
                                    }}
                                />
                            </Animated>
                        </div>
                    </section>

                    <section className="content">
                        <main className="mg__sqareInfo">
                            {BeneficiosData.map((item, index) => {
                                return (
                                    <SquareInfo
                                        key={index}
                                        title={item.title}
                                        title2={item.title2}
                                        icon={item.icon}
                                    />
                                );
                            })}
                        </main>
                    </section>
                </section>
            </div>
        </main>
    );
}
