import React from 'react';
import { Modal } from 'antd';
import { setDisplayModal } from 'reduxStore/slices/components.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import VideoComponent from 'components/video';
export default function TestimoniesModal() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.componentSlice.displayModal);

    return (
        <Modal
            width={992}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                dispatch(setDisplayModal(false));
            }}
        >
            <main className="mg__modal">
                <section className="mg__modal__content">
                    <VideoComponent />
                </section>
            </main>
        </Modal>
    );
}
