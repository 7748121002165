import React from 'react';
import Animated from 'components/AnimateComponent';

export default function Section_9() {
    return (
        <main className="home__section__9">
            <section className="block__title">
                <section className="block__title__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>¿TE GUSTARÍA SER ATENDIDO POR UN ASESOR?</h1>
                    </Animated>

                    <section className="mg__section__container mg__section__container--relative">
                        <div className="block__title__info">
                            <Animated animation={'animate__fadeInUp'}>
                                <h2>
                                    Con todo gusto tendrás la atención que mereces por favor dejamos
                                    tus datos y en breve estarán contigo
                                </h2>
                            </Animated>
                        </div>
                    </section>
                </section>
            </section>
        </main>
    );
}
