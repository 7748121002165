import React from 'react';
import magueyTokenLogo from '../../assets/images/logos/mage-token-logo.png';
import Animated from 'components/AnimateComponent';

export default function Section_1() {
    return (
        <main className="home__section__1">
            <div className="mg__overlay mg__overlay--dark-3">
                <div className="nv__shading__layer"></div>
                <section className="mg__section__container mg__section__container--relative">
                    <div className="data__container">
                        <Animated animation={'animate__fadeInUp'}>
                            <div className="home__section__1__logo">
                                <img src={magueyTokenLogo} alt="Maguey Token Logo" />
                            </div>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <div className="data__container__item">
                                <h1>
                                    ¡ÚNETE A LA <br /> REVOLUCIÓN
                                </h1>
                                <h2 className="text-yellow">DEL AGAVE!</h2>
                            </div>
                        </Animated>
                        <div className="data__container__item">
                            <Animated animation={'animate__fadeInUp'}>
                                <p>
                                    ¿Sabías que el mezcal, es una de las bebidas más populares del
                                    mundo?
                                </p>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <p>
                                    La demanda de agave espadín, la materia prima del mezcal y del
                                    tequila está en constante crecimiento.
                                </p>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <h4>MAGUEYTOKEN®</h4>
                            </Animated>

                            <Animated animation={'animate__fadeInUp'}>
                                <p>
                                    te ofrece la oportunidad única de participar en esta industria
                                    en auge y obtener el mayor rendimiento.
                                </p>
                            </Animated>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
}
