import React from 'react';
import { FaFacebook, FaYoutube, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa';

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <section className="main__page--full mg__footer">
            <section className="mg__footer__container">
                <div className="mg__footer__info">
                    <div className="mg__footer__social">
                        <a href="https://www.facebook.com/magueytoken" target="_blank">
                            <FaFacebook />
                        </a>

                        <a href="https://www.youtube.com/@MagueyToken" target="_blank">
                            <FaYoutube />
                        </a>

                        <a
                            href="https://www.linkedin.com/showcase/magueytoken/posts/?feedView=all"
                            target="_blank"
                        >
                            <FaLinkedin />
                        </a>

                        <a href="https://www.instagram.com/magueytoken/" target="_blank">
                            <FaInstagram />
                        </a>

                        <a href="https://www.tiktok.com/@magueytoken?lang=es" target="_blank">
                            <FaTiktok />
                        </a>
                    </div>
                    <p>
                        Plaza parque, piso 3, Porfirio Díaz 258, colonia Reforma, Oaxaca de Juárez,
                        Oaxaca.
                    </p>
                    <p>
                        Atención general:{' '}
                        <a href="mailto:contacto@magueytoken.com" target="_blank">
                            contacto@magueytoken.com
                        </a>{' '}
                        <br /> Teléfono: <a href="tel:525564931947">+52 55 6493 1947</a>
                    </p>
                    <p>
                        {currentYear} - MAGUEYTOKEN® Todos los derechos reservados{' '}
                        <span className="separator">|</span> <br />
                        <a href="https://magueytoken.io/aviso-legal" target="_blank">
                            Aviso legal
                        </a>
                    </p>
                </div>
            </section>
        </section>
    );
}
