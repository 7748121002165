
import * as yup from 'yup';
export const contactFormSchema = yup
    .object({

        name: yup
            .string()
            .required('Este campo es requerido'),

        email: yup
            .string()
            .required('Este campo es obligatrorio')
            .email('Debes introducir un correo valido'),

        phone: yup
            .string()
            .required('Este campo es obligatrorio')
            .matches(
                /^\+?[1-9]\d{1,14}$/, 
                'Por favor, introduce un número de teléfono válido')
                .min(10, 'El número de teléfono debe tener al menos 10 dígitos'),
    })
    .required();
