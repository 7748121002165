import React from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import ActionButton from 'components/buttons/action';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal, setVideo, setVideoSize } from 'reduxStore/slices/components.slice';
import Animated from 'components/AnimateComponent';

export default function Section_2() {
    const dispatch = useAppDispatch();

    return (
        <main className="home__section__2">
            <div className="mg__overlay mg__overlay--dark-3">
                <section className="mg__section__container mg__section__container--relative">
                    <section className="home__section__2__container">
                        <Animated animation={'animate__fadeInUp'}>
                            <h1>¿Qué es MAGUEYTOKEN®?</h1>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <p>
                                Somos una empresa que te permite obtener tu propia plantación de
                                agave espadín en el hermoso estado de Oaxaca.
                            </p>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <p>
                                Nos destacamos por apostar en el sector de los agronegocios a través
                                de estructuras legales y financieras sólidas, aprovechando la
                                tendencia alcista de la industria del mezcal y sus derivados.{' '}
                            </p>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <p>
                                Implementamos prácticas agrícolas responsables que minimizan el
                                impacto ambiental y benefician a las comunidades locales.{' '}
                            </p>
                        </Animated>

                        <section className="actions__container">
                            <Animated animation={'animate__fadeInUp'}>
                                <ActionButton
                                    text="¡Quiero ser parte!"
                                    type={'big'}
                                    icon={<MdOutlineKeyboardArrowRight />}
                                    onClick={() => {
                                        window.open('https://magueytoken.io/login', '_blank');
                                    }}
                                />
                            </Animated>
                        </section>
                    </section>
                </section>
            </div>
        </main>
    );
}
