import Starts from 'components/starts';
import React from 'react';
import Animated from 'components/AnimateComponent';

interface comunityProps {
    item: any;
}

export default function ComunityComponente({ item }: comunityProps) {
    return (
        <main className="mg__comunity">
            {item.position === 'left' ? (
                <section className=" mg__comunity__container mg__comunity__container--left">
                    <Animated animation={'animate__fadeInLeft'}>
                        <section className="mg__comunity__item">
                            <div className="mg__comunity__item__image">
                                <img src={item.image} alt="comunidad" />
                            </div>
                            <div className="mg__comunity__item__info">
                                <Animated animation={'animate__fadeInRight'}>
                                    <div className="mg__comunity__item__info__container">
                                        <div className="mg__comunity__item__name">
                                            <h1>{item.name}</h1>
                                        </div>

                                        <h3>{item.subtitle}</h3>

                                        <p>{item.description}</p>
                                        <div className="mg__comunity__item__stars">
                                            <Starts />
                                        </div>
                                    </div>
                                </Animated>
                            </div>
                        </section>
                    </Animated>
                </section>
            ) : (
                <section className="mg__comunity__container mg__comunity__container--right">
                    <Animated animation={'animate__fadeInRight'}>
                        <section className="mg__comunity__item mg__comunity__item--right">
                            <div className="mg__comunity__item__image">
                                <img src={item.image} alt="comunidad" />
                            </div>

                            <div className="mg__comunity__item__info">
                                <Animated animation={'animate__fadeInLeft'}>
                                    <div className="mg__comunity__item__info__container">
                                        <div className="mg__comunity__item__name">
                                            <h1>{item.name}</h1>
                                        </div>

                                        <h3>{item.subtitle}</h3>

                                        <p>{item.description}</p>
                                        <div className="mg__comunity__item__stars">
                                            <Starts />
                                        </div>
                                    </div>
                                </Animated>
                            </div>
                        </section>
                    </Animated>
                </section>
            )}
        </main>
    );
}
