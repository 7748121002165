import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';

const items: CollapseProps['items'] = [
    {
        key: '1',
        label: '¿Cómo puedo participar en la industria del agave si no tengo experiencia en el sector?',
        children: (
            <p>
                No tienes que ser un experto en e sector del agave, nosotros nos encargamos de todo
                el proceso, desde la siembra hasta cosecha. Contáctanos ahora.
            </p>
        ),
    },
    {
        key: '2',
        label: '¿Por qué el plazo mínimo del retorno de mi capital es a 6 años?',
        children: (
            <p>
                Debido a que el maguey es un activo biológico, el tiempo de maduración puede variar,
                por lo tanto el plazo mínimo es de 6 años (72 meses) debido a que es el tiempo de
                maduración que requiere una planta de MAGUEYTOKEN®️ para poder ser vendida a
                cualquier productor de mezcal, tequila, miel de maguey u otros derivados de la
                industria.
            </p>
        ),
    },
    {
        key: '3',
        label: '¿Qué rige el precio del maguey al momento de la venta de la plantación?',
        children: (
            <p>
                El precio de mercado real, es decir, el precio que el productor de mezcal o
                derivados está dispuesto a pagar y el precio en el que el productor de maguey está
                dispuesto a vender. Es posible que el precio del maguey pueda bajar impactando el
                rendimiento de la plantación, disminuyéndolo de 46% bruto anual variable a 40% o 30%
                por ejemplo, o puede que el precio suba aumentándolo de 46% anual variable a más,
                sin embargo las proyecciones son a peso mínimo promedio y a precio del mercado real.
            </p>
        ),
    },
    {
        key: '4',
        label: '¿Qué ventajas obtengo al tener una plantación en MAGUEYTOKEN®️?',
        children: (
            <>
                <p>"Comienzas a generar un impacto social desde el momento en que te unes."</p>

                <p>
                    "En MAGUEYTOKEN ®️, la especie que cultivamos, es agave espadín, uno de los
                    agaves más prematuros y con un peso promedio de 112 kg por planta, así que
                    obtendrás mayores rendimientos en comparación a otros agaves y de la variedad
                    Tequilana Weber, que pesa aproximadamente 40 kg por planta en promedio."
                </p>

                <p>
                    "MAGUEYTOKEN ®️ cuenta para cada plantación, un seguro agrícola que ofrece una
                    cobertura de protección a los agaves ante eventualidades climáticas y con un
                    equipo técnico de expertos que siembran, cuidan y mantienen tus plantaciones
                    hasta lograr el desarrollo óptimo."
                </p>

                <p>
                    "Con MAGUEYTOKEN ®️, tienes la oportunidad de ser parte de la industria en
                    tendencia alcista como el mezcal."
                </p>

                <p>
                    Para cada integrante de la familia MAGUEYTOKEN organizamos experiencias únicas y
                    al tener tu plantación obtienes accesos especiales.
                </p>
            </>
        ),
    },

    {
        key: '5',
        label: '¿Qué instituciones respaldan MAGUEYTOKEN?',
        children: (
            <p>
                Debido a que es un modelo de negocio estructurado legalmente, las instituciones que
                respaldan el modelo son todas las de Gobierno que tienen que ver con la Ley de
                Sociedades Mercantiles, Ley del I. S. R., Ley del Mercado de Valores y todas las
                aplicables a nuestra sociedad según lo establecido en nuestra acta constitutiva.
            </p>
        ),
    },
];

export default function Accordeon() {
    return (
        <>
            <div className="mg__acordeon">
                <Collapse defaultActiveKey={['1']} ghost items={items} />
            </div>
        </>
    );
}
