import React from 'react';
import { FaStar } from 'react-icons/fa';

export default function Starts() {
    return (
        <>
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
        </>
    );
}
